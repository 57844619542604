import React, {Suspense} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import {GlobalStyles} from './global-styles';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container)
root.render(
    <React.Fragment>
        <Suspense fallback={null}>
            <GlobalStyles />
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Suspense>
      </React.Fragment>
);
serviceWorker.register();
