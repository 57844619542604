import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        backend: {
            loadPath: 'https://francktaf.com/API/src/post/{{lng}}_translation.json',
          },
        resources: {
            en: {
                translation: en,
            },
            fr: {
                translation: fr,
            },
        },
        debug: true,
        fallbackLng: "fr",
    });

export default i18n;
