import React from 'react';
import Routing from './Router';

const App = () => {

    return (
        <div>
            <Routing />  
        </div>   
    );
};
export default App;
