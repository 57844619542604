import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  
  html, body {
    margin: 0 auto;
    color:#fff;
    max-width: 100%;
    overflow-x: hidden;
    font-size: calc(1em + 0.390625vw);
    background-color: #232946;

  }
  
  body{
    width: 100wh;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
    
  }

`;

