import React, { Suspense, lazy } from 'react';
import { Route, Routes} from 'react-router-dom';
const Accueil = lazy(() => import('../containers/views/Main/accueil'));
const Actualités = lazy(() => import('../containers/views/Main/actualités'));
const Partenaires = lazy(() => import('../containers/views/Main/partenaires'));
const Réalisations = lazy(() => import('../containers/views/Main/réalisations'));
const Services = lazy(() => import('../containers/views/Main/services'));
const Mainer = lazy(() => import('../containers/layouts/Main'));
const NotFound = lazy(() => import('../containers/views/Main/notfound'));
const Contact= lazy(() => import('../containers/views/Main/contact'));



export default function Routing() {
    return (
        <div>
            <Suspense fallback={<div></div>}>
                    {/* <Route exact path="/login" element={<Login />} /> */}
                   
                        <Mainer>
                            <Routes>
                                <Route
                                    exact
                                    path="/"
                                    element={<Accueil />}
                                />
                                <Route
                                    exact
                                    path="/actualités"
                                    element={<Actualités />}
                                />
                                <Route 
                                    exact
                                    path="/contact" 
                                    element={<Contact />} 
                                /> 
                                <Route
                                    exact
                                    path="/partenaires"
                                    element={<Partenaires />}
                                />
                                <Route
                                    exact
                                    path="/réalisations"
                                    element={<Réalisations />}
                                />
                                <Route
                                    exact
                                    path="/services"
                                    element={<Services />}
                                />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Mainer>
            </Suspense>
        </div>
    );
}
